import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';
import moment from 'moment';

const urlCreateTeam = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/create'
const urlUpdateTeam = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/update'

const ConfirmBtn = ({ nombreProp, cargoProp, usuarioProp, rolProp, updateDateProp, titulo, mensaje,
    accion, icono, label, usuarioSesionProp, nameTeamWorkProp, dateStartWorkTeamProp, dateEndWorkTeamProp,
     dataCoordinator, dataOfficial, idClientTeamWork, updateClientTeam, updateDateStartProp, updateDateEndProp,
    updateCoordinatorTeam, updateNameTeam, updateOfficialTeam, idUpdate, dateCreateProp, createByProp, 
    runRenderProp, setRunRenderProp, setDisplayBasicOfficialProp, idTeamWork, classProp, tooltipProp, 
    clientProp, nameTeamProp, dateStartTeamProp, dateEndTeamProp, coordinatorProp, officialProp, 
    setValidateChange,idCoordinator, arrayGroupOfficial, dataContactClienProp, currentErrorProp, messageErrorProp }) => {

    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [disabled, setDisabled] = useState(false)
    const servicio = () => {
        if (accion === "CrearEquipoTrabajo") {
            createTeamWork()
            setDisabled(true)
        }
        else if (accion === "CrearFuncionarios") {
            updateTeamWork()
            setDisabled(true)
        }
        else if (accion === "ActualizarEquipo") {
            updateTeamWork()
            setDisabled(true)
        }
        else if (accion === "ActualizarFuncionario") {
            updateTeamWork()
            setDisabled(true)
        }
        else if (accion === "EliminarFuncionario") {
            setDisabled(true)            
            updateTeamWork()
        }
        else if (accion === "EliminarEquipoTrabajo") {
            setDisabled(true)
            searchActivity()
        }
    }

    const urlCheckAct = process.env.REACT_APP_BASE_URL + '/v1/api/activity/consultListActivityRange'

    const searchActivity = async () => {
        let today = moment().format('YYYY-MM-DD');
            
        const data = {
            fecFinal: today,
            fecInicial: dateStartTeamProp,
            idProyectos: [],
            numeroDocumentos: [],
            idEquipoTrabajos: [idTeamWork],
            idProveedores: []
        };
        
        const json = JSON.stringify(data)
        
        await axios.post(urlCheckAct, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(response =>{
                if(response.data.length === 0){
                    deleteTeamWork()
                }else{
                    setDisplayConfirmation(false)
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El Colaborador tiene actividades con este equipo de trabajo', life: 5000 });
                }
            })
    }

    const createTeamWork = async () => {
        const json = JSON.stringify(
            {
                actualizadopor: '',
                creadopor: usuarioSesionProp,
                estado: 1,
                fechaactualizado: '',
                fechacreacion: new Date(),
                fechafinvigencia: dateEndWorkTeamProp,
                fechainiciovigencia: dateStartWorkTeamProp,
                lider: dataCoordinator,
                nombreequipotrabajo: nameTeamWorkProp,
                cliente: { id: idClientTeamWork },
                usuarios: dataOfficial,
                contactoCliente: dataContactClienProp
            }
        )
        /* console.log(json) */
        await axios.post(urlCreateTeam, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)
                    setDisplayBasicOfficialProp(false)
                    clientProp({
                        customer: '',
                        id: '',
                        dominio: ''
                    })
                    nameTeamProp(null)
                    dateStartTeamProp(null)
                    dateEndTeamProp(null)
                    coordinatorProp('')
                    officialProp([])
                    setValidateChange(false)
                    arrayGroupOfficial([])
                    idCoordinator([])

                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const updateTeamWork = async () => {
        const json = JSON.stringify(
            {
                actualizadopor: usuarioSesionProp,
                cliente: { id: updateClientTeam },
                creadopor: createByProp,
                estado: 1,
                id: idUpdate,
                fechaactualizado: new Date(),
                fechacreacion: dateCreateProp,
                fechafinvigencia: updateDateEndProp,
                fechainiciovigencia: updateDateStartProp,
                lider: updateCoordinatorTeam,
                nombreequipotrabajo: updateNameTeam,
                usuarios: updateOfficialTeam,
                contactoCliente: dataContactClienProp
            }
        )
        
        await axios.post(urlUpdateTeam, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización satisfactoria', detail: response.data.message, life: 3000 });
                // console.log(response)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)
                    setDisplayBasicOfficialProp(false)

                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })


    }

    const urlDelete = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/delete?id=' + idTeamWork

    const deleteTeamWork = async () => {

        await axios.delete(urlDelete,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)

                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
            
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                {
                    currentErrorProp?''
                    :
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => servicio()} autoFocus disabled={disabled}/>
                }
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icono} onClick={() => onClick('displayConfirmation')} className={classProp} tooltip={tooltipProp} style={{ margin: '5px 5px 0px' }}
                tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            <Dialog className="modalConfirm" header={titulo} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                <div className="confirmation-content">
                    {updateDateProp ? "" :
                        <div className="p-fluid p-grid">
                            <div className=" p-col-12 p-md-6">
                                <label className="labelsConfirm" >Nombre</label>
                                <InputText value={nombreProp} disabled />
                            </div>

                            <div className=" p-col-12 p-md-6">
                                <label className="labelsConfirm" >Cargo</label>
                                <InputText value={cargoProp} disabled />
                            </div>

                            <div className=" p-col-12 p-md-6">
                                <label className="labelsConfirm" >Usuario</label>
                                <InputText value={usuarioProp} disabled />
                            </div>

                            <div className=" p-col-12 p-md-6">
                                <label className="labelsConfirm" >rol</label>
                                <InputText value={rolProp} disabled />
                            </div>
                        </div>
                    }

                    <div className="mensajeCofirmar">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span>{currentErrorProp?messageErrorProp:mensaje}</span>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ConfirmBtn