import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const Axios = axios.create({
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
    },
    baseURL: process.env.REACT_APP_BASE_URL + "/v1/api/",
});


/// Clase Services: Se hacen las peticiones get a los servicios corresponientes, pueden ser importadas en cualquier componente.
/// axios: Librería para peticiones a los servicios
/// Cookies: Librería que guarda datos en variables de sesión, usamos una variable de sesión con -> cookies.get('NombreDeLaVariable')

/// Explicación función get con axios:
/*
    Explicación:

    getUsuarios: Nombre dela función
    url: Almacena la url del servicio a consumir.
    process.env.REACT_APP_BASE_URL: Es el llamado a la base url de los servicio, almacenada en el archivo .env
    '/v1/api/user/consultListUser': endPoint de la url, cambia según el servicio
    return axios.get(): Dentro de () se evia la url del servicio y los headers de permisos, retornará la respuesta del servicio
    .then: Si es exitosa retornará los datos en la función
    .catch: Si es fallida retornará una función que mostrará el error en consola

    ejemplo de función:
    getUsuarios(){ ->

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/consultListUser'

        return axios.get(url,
        {
            headers:
            {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        }
        ).then(res=> res.data)

        .catch(function (error) {
            //console.log(error.response)
        })
    }
*/

const paises = [
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "Andorra", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: 'Cote D"Ivoire', code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: 'Korea, Democratic People"S Republic of', code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: 'Lao People"S Democratic Republic', code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
];


const headers = {
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
    }
}
export default class Services {

    constructor() {
        this.source = axios.CancelToken.source()
        this.headers = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
        }
    }
    cancelRequest() {
        if (this.source) {
            this.source.cancel()
        }
    }
    getUsuarios() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/consultListUser'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getUsernameAndTypeBlockOfUser() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/consultUserAndTypeBlock'

        return axios.get(url, { headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getUsuariosWithIdNameAddActivity() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/consultUsers'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getUsersByClient(idsClientesUserSession) {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/listUsersByClient'

        return axios.post(url, idsClientesUserSession, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getUsersForProjectOrTeamWorkOrPosition() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/getUsersFilter'

        return axios.post(url, null, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getUsuariosSomeData() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/consultListUserSpecific1'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getUsuariosAsociadosCliente(id) {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/client/consultListUser/' + id

        return axios.get(url, { headers: this.headers },

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    /* getActividades(usuario) {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/consultListActivity?esadicional=0&usuario=' + usuario

        return axios.get(url, headers:this.headers

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    } */

    /* getActividadesAdicionales(usuario) {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/consultListActivity?esadicional=1&usuario=' + usuario

        return axios.get(url, headers:this.headers

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    } */

    getCargos() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/position'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }
    getTiposDocumento() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/documentType'


        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }


        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }
    getRoles() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/rol'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }
    getTipoActividades() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/typeActivity'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                if (axios.isCancel(error)) {
                    /*   console.log('La solicitud fue cancelada:', error.message); */
                }
            })
    }
    getAplicaciones() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/aplication'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }
    getAreasNegocio() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/areaBusiness'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)


            .catch(function (error) {
                //console.log(error.response)
            })
    }
    getProyectos() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/project'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getNewProyectos() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/project/consultProjects'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }
    getClientes() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/client/consultListClient'


        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getCountries() {
        return axios.get(paises).then(res => res.data.data);
    }

    async getCalendarios() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/calendar'

        return await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }
        }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }
    getPaises() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/calendar/listaPaises'

        return axios.get(url, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getProyectosUsuarios() {

        const idUsuario = cookies.get('idUsuario')

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/projectsUser/' + idUsuario

        return axios.post(url, null, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getClientesProyectosUsuario() {
        const idUsuario = cookies.get('idUsuario')

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/consultListClientForUser/' + idUsuario

        return axios.post(url, null, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                /* console.log(error) */
            })
    }

    getAsociacionProyectoUsuarioVigente() {
        const idUsuario = cookies.get('idUsuario')

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/consultListProjectForUser/' + idUsuario

        return axios.post(url, null, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                /*   console.log(error) */
            })
    }

    getProjectUserByClientAndTypeProject() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/listProjectsUsers'

        return axios.post(url, null, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getUltimoCierre() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/admim/closes/latest'

        return axios.get(url, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getCierres() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/admim/closes'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getHistorico() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/closeHistory'
        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })

    }
    getHistoricoUsuario(id) {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/closeHistory/' + id
        return axios.post(url, null, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getTrimestres() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/period'

        return axios.get(url, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getAnos() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/report/consultListYear'


        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getUltimoPeriodo() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/period/getTrimestreAnterior'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getUsuariosCargaTrabajo() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/report/bulkUsers'

        return axios.get(url, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getAllUsers() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/report/usersSelection'

        return axios.post(url, null, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getNovedad(id) {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/news/getByColaboratorId/' + id

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }


    getDetalleNovedad(id) {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/detailNews/getByIdNews/' + id

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getTipoNovedad() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/typeNews'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getTipoUsuario() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/typeUser'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getTipoBloqueo() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/typeLock'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getInterestedPerson() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/interestedPerson'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }


    async getachievement() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/Achievement'

        return await axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    async getachievementv2(idObjetivos) {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/Achievement/v2'

        return await axios.get(url,{
            headers: this.headers, 
            cancelToken: this.source.token, 
            params: { 
                idObjetivos: idObjetivos.join(',')
            }
        }).then(res => res.data)
            .catch(function (error) {
                //console.log(error.response)
            })
    }

    async getAchievementListShort(idObjetivo) {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/Achievement/short/' + idObjetivo

        return await axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getConsultAchievement() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/Achievement/consultListAchievement'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    async getDeliverables() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/deliverable'

        return await axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    async getDeliverablesV2(idObjetivos) {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/deliverable/v2'

        return await axios.get(url,{
            headers: this.headers, 
            cancelToken: this.source.token, 
            params: { 
                idObjetivos: idObjetivos.join(',')
            }
        }).then(res => res.data)
            .catch(function (error) {
                //console.log(error.response)
            })
    }

    async getDeliverablesByProjects(idProyectos) {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/deliverable/filter'

        return await axios.get(url,{
            headers: this.headers, 
            cancelToken: this.source.token, 
            params: { 
                idProyectos: idProyectos.join(',')
            }
        }).then(res => res.data)
            .catch(function (error) {
                //console.log(error.response)
            })
    }

    async getDeliverablesListShort(idObjetivo) {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/deliverable/short/' + idObjetivo

        return await axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }


    async getObjectives() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/objective'

        return await axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    async getobjectivesV2(idProyectos,fechaInicio,fechaFin){
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/objective/v2'

        return await axios.get(url,{
            headers: this.headers, 
            cancelToken: this.source.token, 
            params: { 
                idProyectos: idProyectos.join(','),
                fechaInicio,
                fechaFin 
            }
        }).then(res => res.data)
            .catch(function (error) {
                //console.log(error.response)
            })
    }

    async getObjectivesV3(idProyectos){
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/objective/filter'
        return await axios.get(url,{
            headers: this.headers, 
            cancelToken: this.source.token, 
            params: { 
                idProyectos: idProyectos.join(',')
            }
        }).then(res => res.data)
            .catch(function (error) {
                //console.log(error.response)
            })
    }

    async getObjectivesShortList(idProyectos) {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/objective/short'

        return await axios.get(url, { headers: this.headers, cancelToken: this.source.token, params: { idProyectos: idProyectos.join(',') } }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getCompany() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/company'

        return axios.get(url, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getCategory() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/category'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getContract() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/contract'

        return axios.get(url, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getWorkTeam() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getWorkTeamByClient(idCliente) {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/consultListByClient/' + idCliente

        return axios.post(url, null, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getWorkTeamSpecific() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/workteamSpecific'

        return axios.get(url, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getServices() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/service'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getOther() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/other'

        return axios.get(url, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getMonthlyReportData(idUser, idCliente, dateStart, dateEnd) {
        const url = process.env.REACT_APP_BASE_URL + `/v1/api/parameter/MonthlyReportData/MonthlyReportData/${idUser}/${idCliente}/${dateStart}/${dateEnd}`

        return axios.post(url, null, { headers: this.headers }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }


    getAllMonthlyReportData(id) {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/MonthlyReportData/MonthlyReportData/allData/' + id

        return axios.post(url, null, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getprojectUser(id) {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/user/projectsUser/' + id

        return axios.post(url, null, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getTypesProjects() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/typesprojects/get'

        return axios.post(url, null, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${sessionStorage.getItem('token')}`
            }, cancelToken: this.source.token
        }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getSuppliers() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/supplier/consultListSupplier'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getStrategicObjectives() {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/strategicObjectives'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    getObjectivesByClient(idCliente) {

        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/strategicObjectives/objectives/' + idCliente

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

    dashBoardTypeActivity(data) {

        return Axios.post("report/dashBoardTypeActivity", data, { headers: this.headers })
            .then((res) => res.data)
            .catch((res) => res.response);
    }

    dashboardTypeActivityProject(data) {

        return Axios.post("report/dashBoardTypeActivityProject", data, { headers: this.headers })
            .then((res) => res)
            .catch((res) => res.response);

    }

    newDashboardTypeActivityProject(data) {

        return Axios.post("report/newDashBoardTypeActivityProject", data, { headers: this.headers })
            .then((res) => res)
            .catch((res) => res.response);

    }

    getDataSpecificProject() {
        const url = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/project/dataSpecificProject'

        return axios.get(url, { headers: this.headers, cancelToken: this.source.token }

        ).then(res => res.data)

            .catch(function (error) {
                //console.log(error.response)
            })
    }

}

export const dashBoardTypeActivity = async (data) => {

    return await Axios.post("report/dashBoardTypeActivity", data, { headers: this.headers })
        .then((res) => res)
        .catch((res) => res.response);
};
