import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { Divider } from 'primereact/divider';
import VerClientesProyectos from "./VerClientesProyectos";
import BtnCrearObjetivo from "./BtnCrearObjetivo";
import ConfirmBtn from "./ConfirmBtn";
import BtnActualizarObjetivo from "./BtnActualizarObjetivo";
import Cookies from "universal-cookie";
import { UpdateValidity } from "../ActualizacionVigencias";
import { CambiarFormatoFecha, isEqual } from "../Funciones";
import BtnVerObjetivosNoVigentes from "./BtnVerObjetivosNoVigentes";
import { UseMedia } from '../Funciones';
import { Button } from "primereact/button";
import BtnModalAdvertencia from "./BtnModalAdvertencia";
import Services from "../../service/Services";
import axios from "axios";
const cookies = new Cookies();

const ConsultarObjetivos = ({ permissionsProp, fullTableProp, individualMonthReportProp, idProjectProp, projectProp, getClientsProp, dateStartValidityProp, dateEndValidityProp,
    setSelectedObjectiveProp, selectedObjectiveProp, objectMonthlyReportDataProp, readReportProp, listReportsUsersProp, reloadObjectiveProp, setReloadObjectiveProp,
    setDisabledIndexProp, disabledIndexProp, objectivesProp, runRenderBlockProp, setRunRenderBlockProp, projectsUserSessionProp, listAchievementProp, listDeliverableProp,
    monthlyReportDataAllDataProp, selectedAchievementProp, selectedDeliverableProp, loadingProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp, setValidateObjectiveNotCurrentProp, showAllDataProp, sendObjectivesExportProp,
    servicesObjectivesProp, runRenderProp2, setRunRenderProp }) => {

    const getServices = new Services();
    const [objectives, setObjectives] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingProj,setloadingProj] = useState(true)
    const [isMonthlyRep,setisMonthlyRep] = useState(true)
    const [projects,setProjects] = useState([])
    

    const [runRender, setRunRender] = useState(0)
    const [messageToast, setMessageToast] = useState('')
    const [validateObjectiveNotCurrent, setValidateObjectiveNotCurrent] = useState(false)

    const toast = useRef(null);

    const usuario = cookies.get('usuario')

    useEffect(() => {
        let source;

        if (fullTableProp === false && servicesObjectivesProp !== undefined) {
            setObjectives(servicesObjectivesProp)
            const urlUpdate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/objective/update'
            servicesObjectivesProp.forEach(x => {
                x.proyectos.forEach(y => {
                    if (y.fechafinvigencia < CambiarFormatoFecha(new Date()) && y.estado !== 0) {
                        const json = JSON.stringify({
                            actualizadopor: x.actualizadopor,
                            creadopor: x.creadopor,
                            descripcion: x.descripcion,
                            estado: x.estado,
                            fechaactualizado: x.fechaactualizado,
                            fechacreacion: x.fechacreacion,
                            id: x.id,
                            proyectos: [{
                                estado: 0,
                                fechacreacion: y.fechacreacion,
                                fechacumplimiento: y.fechacumplimiento,
                                fechafinvigencia: y.fechafinvigencia,
                                fechainiciovigencia: y.fechainiciovigencia,
                                id: y.id,
                                porcentajecumplimiento: y.porcentajecumplimiento,
                                proyecto: {
                                    id: y.proyecto.id
                                },
                            }]
                        })
                        UpdateValidity(urlUpdate, json).then(data => {
                            setMessageToast(data.message)
                            setLoading(data.loading)
                            setRunRender(runRender + 1)
                        })
                    } else {
                        setLoading(false)
                    }
                })
                setLoading(false);
            })            
        }
        
        if (messageToast !== '') {
            toast.current.show({ severity: 'success', summary: messageToast, detail: '', life: 3000 });
        }

        return () => {
            if (source !== undefined && source) {
                getServices.cancelRequest()
            }
        }
    }, [fullTableProp, servicesObjectivesProp, individualMonthReportProp]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fullTableProp === true) {
            if (readReportProp === true) {
                if (listReportsUsersProp.length !== 0) {
                    var newObjectives = JSON.parse(listReportsUsersProp[0].objetivos);
                    var filterProject = newObjectives.filter(obj => obj.proyecto === idProjectProp);
                    var listObjectives = []
                    filterProject.forEach(element => {
                        const result = objectivesProp.filter(function (object) {
                            var id = parseInt(object.id);
                            return id === element.id;
                        });
                        result.forEach((element) => {
                            listObjectives.push(element);
                        });
                    })
                    var filterObjective = []
                    const resultFilter = listObjectives.map(item => {
                        return item.proyectos.map(j => {
                            var boolean = undefined
                            if (j.proyecto.id === parseInt(idProjectProp)) {
                                boolean = item
                            }
                            return boolean
                        })
                    })
                    resultFilter.forEach(i => {
                        i.forEach(j => {
                            if (j !== undefined) {
                                if (j.length !== 0) {
                                    filterObjective.push(j)
                                }
                            }
                        })
                    })
                    const tableData = {}
                    const uniqueData = filterObjective.filter(function (object) {
                        return tableData.hasOwnProperty(object.id) ? false : (tableData[object.id] = true)
                    })
                    const newUniqueData = uniqueData.map(item => {
                        return {
                            actualizadopor: item.actualizadopor,
                            creadopor: item.creadopor,
                            descripcion: item.descripcion,
                            estado: item.estado,
                            fechaactualizado: item.fechaactualizado,
                            fechacreacion: item.fechacreacion,
                            id: item.id,
                            proyectos: item.proyectos,
                            proyecto: idProjectProp
                        }
                    })
                    setObjectives(newUniqueData)
                    setSelectedObjectiveProp(newUniqueData)
                    setLoading(false)
                }
            } else {
                var objectives = []
                const result = objectivesProp.map(item => {
                    return item.proyectos.map(j => {
                        var boolean = undefined
                        if (j.proyecto.id === parseInt(idProjectProp)) {
                            boolean = item
                        }
                        return boolean
                    })
                })
                result.forEach(i => {
                    i.forEach(j => {
                        if (j !== undefined) {
                            if (j.length !== 0) {
                                objectives.push(j)
                            }
                        }
                    })
                })
                const tableData = {}
                const uniqueData = objectives.filter(function (object) {
                    return tableData.hasOwnProperty(object.id) ? false : (tableData[object.id] = true)
                })
                const newUniqueData = uniqueData.map(item => {
                    return {
                        actualizadopor: item.actualizadopor,
                        creadopor: item.creadopor,
                        descripcion: item.descripcion,
                        estado: item.estado,
                        fechaactualizado: item.fechaactualizado,
                        fechacreacion: item.fechacreacion,
                        id: item.id,
                        proyectos: item.proyectos,
                        proyecto: idProjectProp
                    }
                })
                var newUniqueDataCreatedBy = newUniqueData.filter(function (object) {
                    var createdBy = object.creadopor
                    return createdBy === usuario
                })
                var newUniqueDataNoCreatedBy = newUniqueData.filter(function (object) {
                    var createdBy = object.creadopor
                    return createdBy !== usuario
                })
                var sendData = newUniqueDataCreatedBy.concat(newUniqueDataNoCreatedBy)
                /* Obtener objetivos vigentes y no vigentes para mostrar en la grilla
                 Si uno de los objetivos no está vigente pero está seleccionado se mostrará en la grilla 
                Pero le indicará al usuario que se debe deseleccionar */
                try {
                    var newDateStartMonthlyReport = CambiarFormatoFecha(dateStartMonthlyReportProp)
                    var newDateEndMonthlyReport = CambiarFormatoFecha(dateEndMonthlyReportProp)
                    var objectivesNotCurrent = []
                    var objectivesCurrent = []
                    sendData.forEach(element => {
                        element.proyectos.forEach(e => {
                            if (e.proyecto.id === parseInt(idProjectProp)) {
                                if (e.fechafinvigencia < newDateStartMonthlyReport || e.fechainiciovigencia > newDateEndMonthlyReport) {
                                    objectivesNotCurrent.push(element)
                                }
                                else {
                                    objectivesCurrent.push(element)
                                }
                            }
                        })
                    })

                    if (selectedObjectiveProp.length !== 0) {
                        selectedObjectiveProp?.forEach(element => {
                            const getObjectivesSelected = objectivesNotCurrent?.filter(function (obj) {
                                return element.id === obj.id
                            })
                            getObjectivesSelected?.forEach(element => {
                                setNewArrObjectivesNotCurrent(element)
                            })
                            if (getObjectivesSelected.length !== 0) {
                                setValidateObjectiveNotCurrent(true)
                                setValidateObjectiveNotCurrentProp(true)
                            }
                            const dataConcat = getObjectivesSelected.concat(objectivesCurrent)
                            const tableDataObjetives = {}
                            const uniqueDataObjectives = dataConcat.filter(function (object) {
                                return tableDataObjetives.hasOwnProperty(object.id) ? false : (tableDataObjetives[object.id] = true)
                            })
                            setObjectives(uniqueDataObjectives)

                        })
                    } else {
                        if (loadingProp === false) {
                            const newTableDataObjetives = {}
                            const newUniqueDataObjectives = objectivesCurrent.filter(function (object) {
                                return newTableDataObjetives.hasOwnProperty(object.id) ? false : (newTableDataObjetives[object.id] = true)
                            })
                            setObjectives(newUniqueDataObjectives)
                        }
                    }
                } catch (error) {
                    /* console.error(error.message); */
                }
                setLoading(false)
            }
        }

    }, [runRender, idProjectProp, readReportProp, listReportsUsersProp, objectivesProp, loadingProp]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(getClientsProp === undefined && showAllDataProp === undefined) setisMonthlyRep(false)
    }, [getClientsProp, showAllDataProp]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const fetchProjects = async() =>{
            setloadingProj(true)
            let info = await getServices.getAsociacionProyectoUsuarioVigente();
            if(info !== undefined){
                return info
            }
        }

        if(isMonthlyRep === false){
            fetchProjects().then(fetchedProjects =>{
                const projectOptions = fetchedProjects.map(proj =>({
                    label: proj.nombre,
                    value: proj.id
                }));
                setProjects(projectOptions)
                setloadingProj(false)
            }).catch(error =>{
                // console.error('Error fetching...',error);
            });
        }

    },[isMonthlyRep]) // eslint-disable-line react-hooks/exhaustive-deps

    const dt = useRef(null);
    //////

    const [showMessage, setShowMessage] = useState(false);

    const validateAssociations = (rowData, achievements, deliverables) => {
        var objectiveAchivement = achievements?.filter(obj => obj.objetivo.id === rowData.id && obj.proyecto.id === parseInt(idProjectProp))

        var objectiveDeliverable = []
        deliverables.forEach(element => {
            element.objetivos.forEach(x => {
                if (x.objetivo.id === rowData.id && x.proyecto.id === parseInt(idProjectProp)) {
                    objectiveDeliverable.push(element)
                }
            })
        })
        var uniqueObjectiveDeliverable = [...new Set(objectiveDeliverable)]

        return {
            objectiveAchivement,
            uniqueObjectiveDeliverable,
        }
    }

    const newValidateAssociations = (objectives, achievements, deliverables) => {
        //Filtrar los objetivos que estén guardados en el informe
        let getObjectivesSaves = objectMonthlyReportDataProp !== undefined ? JSON.parse(objectMonthlyReportDataProp.objetivos) : []
        let newObjectives = null

        getObjectivesSaves?.forEach(i => {
            if (i.id === objectives.id) {
                newObjectives = objectives
            }
        })

        let objectiveDeliverable = []
        // Filtrar listado de logros completo
        let getNewResultAchievement = []
        achievements.forEach(i => {
            const newResultDeliverables = listAchievementProp.filter(item => {
                return item.id === i.id
            })
            newResultDeliverables.forEach(z => {
                getNewResultAchievement.push(z)
            })
        })

        const objectiveAchievement = getNewResultAchievement?.filter(obj => obj.objetivo.id === newObjectives?.id && obj.proyecto.id === parseInt(idProjectProp))

        // Filtrar entregables por el proyecto del objetivo
        const resultDeliverables = deliverables.filter(item => {
            return item.proyecto === idProjectProp
        })

        //Filtrar listado de entregables completo
        let getNewResultDeliverables = []
        resultDeliverables.forEach(i => {
            const newResultDeliverables = listDeliverableProp.filter(item => {
                return item.id === i.id
            })
            newResultDeliverables.forEach(z => {
                getNewResultDeliverables.push(z)
            })
        })

        //Filtrar objetivos asociados al entregable para deshabilitarlo
        getNewResultDeliverables.forEach(i => {
            const filter = i.objetivos.filter(item => {
                return item.objetivo.id === newObjectives?.id
            })
            filter.forEach(e => {
                objectiveDeliverable.push(e)
            })
        })
        var uniqueObjectiveDeliverable = [...new Set(objectiveDeliverable)]

        return {
            objectiveAchievement,
            uniqueObjectiveDeliverable,
        }
    }


    const matchesAchievement = listAchievementProp?.filter(obj1 => selectedAchievementProp?.some(obj2 => isEqual(obj1, obj2)));
    const matchesDeliverable = listDeliverableProp?.filter(obj1 => selectedDeliverableProp?.some(obj2 => isEqual(obj1, obj2)));

    /*  const isRowSelectable = (event) => {
         const data = event.data;
         var showRow = true
         var { objectiveAchivement, uniqueObjectiveDeliverable } = validateAssociations(data, matchesAchievement, matchesDeliverable);
         if (objectiveAchivement.length !== 0 || uniqueObjectiveDeliverable.length !== 0) {
             showRow = false
            
         }
         return showRow
     } */
    const rowClassName = (data) => {
        let getAchievement = objectMonthlyReportDataProp !== undefined ? JSON.parse(objectMonthlyReportDataProp.logros) : []
        let getDeliverables = objectMonthlyReportDataProp !== undefined ? JSON.parse(objectMonthlyReportDataProp.entregables) : []

        var showRow = true
        var { objectiveAchievement, uniqueObjectiveDeliverable } = newValidateAssociations(data, getAchievement, getDeliverables);

        if (objectiveAchievement.length !== 0 || uniqueObjectiveDeliverable.length !== 0) {
            showRow = false
            setShowMessage(true)
        }
        return showRow ? '' : 'p-disabled';
    }
    /////

    const [selectedObjective, setSelectedObjective] = useState(null);

    const onRowSelect = (e) => {
        var newObject = {
            id: e.data.id,
            proyecto: idProjectProp
        }
        setSelectedObjectiveProp([...selectedObjectiveProp, newObject])
    }

    const onRowUnselect = (e) => {
        const result = selectedObjectiveProp.filter(function (object) {
            var id = object.id
            var idProject = object.proyecto
            return id !== e.data.id || idProject !== e.data.proyecto
        })
        setSelectedObjectiveProp(result)
    }
    const [validateSelectAll, setValidateSelectAll] = useState(false)
    const [lengthObjectives, setLengthObjectivesProp] = useState(0)
    const [selectedAccept, setSelectedAccept] = useState(null)
    const [newRunRender, setNewRunRender] = useState(0)
    useEffect(() => {
        if (selectedAccept !== null && selectedAccept === false && individualMonthReportProp === true && newRunRender) {
            if (objectMonthlyReportDataProp !== undefined) {
                var arrayObjective = JSON.parse(objectMonthlyReportDataProp.objetivos)
                var result = arrayObjective?.filter(function (object) {
                    var idProject = object.proyecto
                    return String(idProject) === String(idProjectProp)
                })
                setSelectedObjective(result)
                const uniqueCombos = {};
                // Recorrer el array original y filtrar los datos
                let newDataFilteredObjectives = []
                for (const data of arrayObjective) {
                    const comboKey = `${data.id}-${data.proyecto}`;

                    if (!uniqueCombos[comboKey]) {
                        uniqueCombos[comboKey] = true;
                        newDataFilteredObjectives.push(data);
                    }
                }
                setSelectedObjectiveProp(newDataFilteredObjectives)
            } else {
                setSelectedObjective([])
                setSelectedObjectiveProp([])
            }
            setValidateSelectAll(false)
        }

    }, [selectedAccept, individualMonthReportProp, newRunRender, objectMonthlyReportDataProp, idProjectProp])// eslint-disable-line react-hooks/exhaustive-deps

    const onSelectedObjectiveChange = (e) => {
        var sendData = e.value.map(item => {
            return {
                id: item.id,
                proyecto: idProjectProp,
                proyectos: item.proyectos
            }
        })
        if (e.type === "all") {
            if (e.value.length !== 0) {
                setValidateSelectAll(true)
                var arrayData = selectedObjectiveProp.concat(sendData)
                var arrayDataMap = arrayData.map(item => {
                    return [JSON.stringify(item), item]
                })
                var arrayDataMapArr = new Map(arrayDataMap)
                var uniqueData = [...arrayDataMapArr.values()]
                const newDataFiltered = [];
                const uniqueCombos = {};
                // Recorrer el array original y filtrar los datos
                for (const data of uniqueData) {
                    const comboKey = `${data.id}-${data.proyecto}`;

                    if (!uniqueCombos[comboKey]) {
                        uniqueCombos[comboKey] = true;
                        newDataFiltered.push(data);
                    }
                }
                setSelectedObjectiveProp(newDataFiltered)
                setSelectedObjective(newDataFiltered)
                setLengthObjectivesProp(e.value?.length)
            } else {
                setValidateSelectAll(false)
                setDisabledIndexProp(true);
                var filterObjetiveOtherProjects = selectedObjectiveProp.filter(obj => obj.proyecto !== idProjectProp);
                var filterObjectiveProject = selectedObjectiveProp.filter(obj => obj.proyecto === idProjectProp);
                var disabledObjectives = filterObjectiveProject.map(item => {
                    var { objectiveAchivement, uniqueObjectiveDeliverable } = validateAssociations(item, matchesAchievement, matchesDeliverable);
                    return {
                        ...item,
                        disabled: objectiveAchivement.length !== 0 || uniqueObjectiveDeliverable.length !== 0 ? true : false
                    }
                });
                var sendDisabledObjective = disabledObjectives.filter(obj => obj.disabled === true).map(item => {
                    return {
                        id: item.id,
                        proyecto: item.proyecto
                    }
                });
                setSelectedObjectiveProp(filterObjetiveOtherProjects.concat(sendDisabledObjective));
                setSelectedObjective(sendDisabledObjective);
            }
        } else {
            setSelectedObjective(sendData);
        }

        if (e.value.length !== 0) {
            setDisabledIndexProp(true)
        }
    }
    const [newArrObjectivesNotCurrent, setNewArrObjectivesNotCurrent] = useState([])
    useEffect(() => {
        try {

            var newDateStartMonthlyReport
            var newDateEndMonthlyReport
            if (dateStartMonthlyReportProp && dateEndMonthlyReportProp) {
                newDateStartMonthlyReport = CambiarFormatoFecha(dateStartMonthlyReportProp)
                newDateEndMonthlyReport = CambiarFormatoFecha(dateEndMonthlyReportProp)
            }
            var objectivesNotCurrent = []
            var objectivesCurrent = []
            selectedObjective?.forEach(element => {
                element?.proyectos?.forEach(i => {
                    if (i.proyecto.id === parseInt(idProjectProp)) {
                        if (i.fechafinvigencia < newDateStartMonthlyReport || i.fechainiciovigencia > newDateEndMonthlyReport) {
                            objectivesNotCurrent.push(element)

                        } else {
                            objectivesCurrent.push(element)
                        }
                    }
                })
            })
            objectivesNotCurrent.forEach(element => {
                const newResult = objectives.filter(item => {
                    return item.id === element.id
                })
                newResult.forEach(i => {
                    setNewArrObjectivesNotCurrent(i)
                })
            })
            if (objectivesNotCurrent.length !== 0) {
                setValidateObjectiveNotCurrent(true)
                setValidateObjectiveNotCurrentProp(true)
            } else {
                setValidateObjectiveNotCurrent(false)
                setValidateObjectiveNotCurrentProp(false)
            }

        } catch (error) {
            //console.log(error.message);
        }
    }, [selectedObjective, dateStartMonthlyReportProp, dateEndMonthlyReportProp, idProjectProp])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (objectMonthlyReportDataProp !== undefined) {
            var arrayObjective = JSON.parse(objectMonthlyReportDataProp.objetivos)
            var result = arrayObjective?.filter(function (object) {
                var idProject = object.proyecto
                return String(idProject) === String(idProjectProp)
            })
            setSelectedObjective(result)
            const array = JSON.parse(objectMonthlyReportDataProp.objetivos)
            const uniqueCombos = {};
            // Recorrer el array original y filtrar los datos
            let newDataFilteredObjectives = []
            for (const data of array) {
                const comboKey = `${data.id}-${data.proyecto}`;

                if (!uniqueCombos[comboKey]) {
                    uniqueCombos[comboKey] = true;
                    newDataFilteredObjectives.push(data);
                }
            }
            setSelectedObjectiveProp(newDataFilteredObjectives)
        } else {
            setSelectedObjective([])
            if (setSelectedObjectiveProp !== undefined) {
                setSelectedObjectiveProp([])
            }
        }
    }, [objectMonthlyReportDataProp, setSelectedObjectiveProp, idProjectProp])

    useEffect(() => {
          
        if(individualMonthReportProp === true){
            setRunRenderProp(runRender +1 )
        }

    }, [runRender, individualMonthReportProp])// eslint-disable-line react-hooks/exhaustive-deps


    var permissionsObjectiveCreate
    if (permissionsProp[17].permisos[0] === "Crear") {
        permissionsObjectiveCreate = true
    } else {
        permissionsObjectiveCreate = false
    }
    var permissionsObjectiveUpdate
    if (permissionsProp[17].permisos[1] === "Editar") {
        permissionsObjectiveUpdate = true
    } else {
        permissionsObjectiveUpdate = false
    }
    var permissionsObjectiveDelete
    if (permissionsProp[17].permisos[2] === "Eliminar") {
        permissionsObjectiveDelete = true
    } else {
        permissionsObjectiveDelete = false
    }

    const acctions = (rowData) => {
        var noDeleteObjective = false
        var menssageDelete = '¿Está seguro de eliminar este objetivo?, Recuerde que una vez se elimine, se borrará definitivamente del sistema'
        var filterObjective = []

        var monthlyReportDataAllDataPropObjective = monthlyReportDataAllDataProp.map(item => {
            return JSON.parse(item.objetivos)
        })

        monthlyReportDataAllDataPropObjective.forEach(e => {
            const result = e.filter(function (obj) {
                return obj.id === rowData.id
            })
            result.forEach(e => {
                filterObjective.push(e)
            })
        })

        var uniqueFilterObjective = [...new Set(filterObjective)]
        if (rowData.creadopor !== usuario) {
            noDeleteObjective = true
            menssageDelete = 'No puede eliminar registros creados por otro usuario'
        }
        else if (uniqueFilterObjective.length !== 0) {
            noDeleteObjective = true
            menssageDelete = 'No puede eliminar este objetivo por que ya ha sido usado por otro usuario'
        }
        else if (listAchievementProp && listDeliverableProp) {
            var { objectiveAchivement, uniqueObjectiveDeliverable } = validateAssociations(rowData, listAchievementProp, listDeliverableProp);
            if (objectiveAchivement.length !== 0 || uniqueObjectiveDeliverable.length !== 0) {
                noDeleteObjective = true
                menssageDelete = 'No puedo eliminar este objetivo por que tiene logros o entregables asociados'
            }
        }  

        return (
            <>
                {permissionsObjectiveUpdate ?
                    readReportProp ?
                        null
                        :
                        <BtnActualizarObjetivo
                            objectiveProp={rowData.descripcion}
                            idObjectiveProp={rowData.id}
                            creationDateProp={rowData.fechacreacion}
                            createdByProp={rowData.creadopor}
                            individualMonthReportProp={individualMonthReportProp}
                            setRunRenderProp={setRunRender}
                            runRenderProp={runRender}
                            runRenderBlockProp={runRenderBlockProp}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            updateDatailProp={false}
                            reloadObjectiveProp={reloadObjectiveProp}
                            setReloadObjectiveProp={setReloadObjectiveProp}
                            objectivesProp={objectivesProp}
                            createProjectsProp={rowData.proyectos}
                            dataObjetiveProp={objectives}
                        />
                    :
                    ""
                }
                <VerClientesProyectos
                    projectsProp={rowData.proyectos}
                    objectiveProp={rowData.descripcion}
                    idObjectiveProp={rowData.id}
                    creationDateProp={rowData.fechacreacion}
                    createdByProp={rowData.creadopor}
                    idProjectProp={idProjectProp}
                    projectProp={projectProp}
                    dateStartValidityProp={dateStartValidityProp}
                    dateEndValidityProp={dateEndValidityProp}
                    individualMonthReportProp={individualMonthReportProp}
                    setRunRenderProp={setRunRender}
                    runRenderProp={runRender}
                    runRenderBlockProp={runRenderBlockProp}
                    setRunRenderBlockProp={setRunRenderBlockProp}
                    getClientsProp={getClientsProp}
                    permissionsProp={permissionsProp}
                    readReportProp={readReportProp}
                    reloadObjectiveProp={reloadObjectiveProp}
                    setReloadObjectiveProp={setReloadObjectiveProp}
                    dateStartMonthlyReportProp={dateStartMonthlyReportProp}
                    dateEndMonthlyReportProp={dateEndMonthlyReportProp}
                    runRenderProp2={runRenderProp2}
                />
                {permissionsObjectiveDelete ?
                    readReportProp ?
                        null
                        :
                        <ConfirmBtn
                            tittle="Confirmar Eliminación"
                            menssage={menssageDelete}
                            action="Delete"
                            label=""
                            classProp="p-button-danger"
                            icon="pi pi-trash"
                            tooltipProp="Eliminar"
                            idObjectiveProp={rowData.id}
                            individualMonthReportProp={individualMonthReportProp}
                            setRunRenderProp={setRunRender}
                            setRunRenderBlockProp={setRunRenderBlockProp}
                            runRenderBlockProp={runRenderBlockProp}
                            runRenderProp={runRender}
                            reloadObjectiveProp={reloadObjectiveProp}
                            setReloadObjectiveProp={setReloadObjectiveProp}
                            noDeleteObjectiveProp={noDeleteObjective}
                        />
                    :
                    ""
                }

            </>
        )
    }


    const exportExcel = () => {
        const newArrWithOutId = sendObjectivesExportProp.map(item => {
            const { id, ...rest } = item; // Usamos destructuring para excluir la propiedad 'id'
            return rest;
        })
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(newArrWithOutId);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'Datos');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {permissionsObjectiveCreate ?
                readReportProp || showAllDataProp || isMonthlyRep === false?
                    null
                    :
                    <BtnCrearObjetivo
                        idProjectProp={idProjectProp}
                        projectProp={projectProp}
                        getClientsProp={getClientsProp}
                        dateStartValidityProp={dateStartValidityProp}
                        dateEndValidityProp={dateEndValidityProp}
                        individualMonthReportProp={individualMonthReportProp}
                        setRunRenderProp={setRunRender}
                        runRenderProp={runRender}
                        runRenderBlockProp={runRenderBlockProp}
                        setRunRenderBlockProp={setRunRenderBlockProp}
                        reloadObjectiveProp={reloadObjectiveProp}
                        setReloadObjectiveProp={setReloadObjectiveProp}
                        objectivesProp={objectivesProp}
                        projectsUserSessionProp={projectsUserSessionProp}
                        dateStartMonthlyReportProp={dateStartMonthlyReportProp}
                        dateEndMonthlyReportProp={dateEndMonthlyReportProp}
                        dataObjetiveProp={objectives}
                    />
                :
                ""
            }
            {loading === true ? <p>Cargando información...</p> : ''}
            {showAllDataProp ?
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLSX" />
                :
                null
            }
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>

        </div>

    );

    let small = UseMedia("(max-width: 760px)");

    const [projectFilter, setProjectFilter] = useState('');
    const cancelTokenSourceRef = useRef(null);
    const userRole = cookies.get('code')
    const [viewOption, setViewOption] = useState('multiSelect'); 

    const fetchObjectives = (projectIds) => {
        setLoading(true);
        
        // Cancelar solicitud anterior si existe
        if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel();
        }
        
        // Crear nuevo token de cancelación
        const source = axios.CancelToken.source();
        cancelTokenSourceRef.current = source;
        
        // Asignar el mismo token al servicio
        getServices.source = source;  // Sincronizar los tokens
        
        setObjectives([]);
        
        return getServices.getObjectivesV3(projectIds)
            .then(data => {
                if (cancelTokenSourceRef.current === source) {
                    setObjectives(data);
                    setLoading(false);
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    // console.log('Request canceled', error.message);
                } else {
                    // console.error('Error fetching objectives:', error);
                }
            })
    };
    
    useEffect(() => {
        if(isMonthlyRep === false){
            if (!projectFilter || projectFilter.length === 0) {
                setLoading(true);
                setObjectives([]);
                return;
            }
            fetchObjectives(projectFilter);
        }
        return () => {
            if (cancelTokenSourceRef.current) {
                cancelTokenSourceRef.current.cancel();
            }
        };
    }, [runRender,projectFilter]);// eslint-disable-line react-hooks/exhaustive-deps
    
    const handleProjectsChange = (e) => {
        setProjectFilter(e.value);
    };
    
    const handleViewOptionChange = (e) => {
        
        const newValue = e.value;
        setViewOption(newValue);
        
        setObjectives([]);
        setLoading(true);
        
        // Cancelar cualquier solicitud pendiente después de actualizar el viewOption
        if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel();
        }
        
        // Limpiar projectFilter si cambia la vista
        if (projectFilter && projectFilter.length > 0) {
            setLoading(true);
            setProjectFilter([]);
        }
        
        // Realizar la nueva solicitud solo después de completar las cancelaciones anteriores
        if (newValue === 'showAllObjectives') {
            // Usar setTimeout para asegurar que esta acción ocurra en el siguiente ciclo de eventos
            setTimeout(() => {
                fetchAllObjectives();
            }, 0);
        }
    };
    
    const fetchAllObjectives = async () => {
        // Cancelar solicitud anterior si existe
        if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel();
        }
    
        // Crear nuevo token de cancelación
        const source = axios.CancelToken.source();
        cancelTokenSourceRef.current = source;
        
        // Asignar el mismo token al servicio
        getServices.source = source;  // Sincronizar los tokens
    
        setLoading(true);
        setObjectives([]);      
        try {
            let objectives = await getServices.getObjectives();
            if (cancelTokenSourceRef.current === source) {
                setObjectives(objectives);
                setLoading(false);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // console.log('Request canceled', error.message);
            } else {
                // console.error('Error fetching all objectives:', error);
            }
        }
    };

    return (
        <div className="datatable-responsive-demo">
            <Toast ref={toast} />
            {
                individualMonthReportProp === true && validateSelectAll === true ? <BtnModalAdvertencia
                    setSelectedAcceptProp={setSelectedAccept}
                    lengthObjectivesProp={lengthObjectives}
                    runRenderProp={newRunRender}
                    setRunRenderProp={setNewRunRender}
                /> : ''
            }
            {readReportProp === true && showAllDataProp === false ?
                null :
                showMessage && <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12" style={{ marginTop: '15px' }}>
                            <Message severity="info" text="Los objetivos se deshabilitan cuando selecciona un entregable o logro asociado, si desea deseleccionar el objetivo retire primero los logros o entregables relacionados." />
                        </div>
                    </div>
                </div>}
            {validateObjectiveNotCurrent === true && showAllDataProp === false ?
                <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-12" style={{ marginTop: '15px' }}>
                            <Message severity="error" text="Uno de los objetivos seleccionados está fuera del rango del periodo seleccionado" />
                        </div>

                        <div className="p-field p-col-12 p-md-4" style={{ marginTop: '15px' }}>
                            <BtnVerObjetivosNoVigentes
                                objectivesNotCurrentProp={newArrObjectivesNotCurrent}
                            />
                        </div>
                    </div>
                </div>
                :
                null
            }
            <div className="card">
            <div className="p-field p-col-12 p-lg-12" style={{ marginBottom: '1rem' }}>
                {isMonthlyRep === false && (
                    <>
                        {userRole.toLowerCase().includes('admin') || userRole.toLowerCase().includes('super') ? (
                            <>
                                <div className="p-grid" style={{ alignItems: 'start', marginBottom: '0.5rem' }}>
                                    <div className="p-col">
                                        <div className="p-field-radiobutton" style={{ marginBottom: '0.5rem' }}>
                                            <RadioButton
                                                inputId="multiSelectView"
                                                name="viewOption"
                                                value="multiSelect"
                                                onChange={handleViewOptionChange}
                                                checked={viewOption === 'multiSelect'}
                                            />
                                            <label className="labels" htmlFor="multiSelectView" style={{ fontWeight: '100', marginLeft: '0.5rem' }}>
                                                Seleccionar Proyectos
                                            </label>
                                        </div>
                                        <div className="p-field-radiobutton">
                                            <RadioButton
                                                inputId="showAllObjectivesView"
                                                name="viewOption"
                                                value="showAllObjectives"
                                                onChange={handleViewOptionChange}
                                                checked={viewOption === 'showAllObjectives'}
                                            />
                                            <label className="labels" htmlFor="showAllObjectivesView" style={{ fontWeight: '100', marginLeft: '0.5rem' }}>
                                                Mostrar Todos los Objetivos
                                            </label>
                                        </div>
                                    </div>
                                    <div className="p-col-fixed" style={{ alignSelf: 'start' }}>
                                        {permissionsObjectiveCreate
                                            ?
                                            <BtnCrearObjetivo 
                                                idProjectProp={idProjectProp}
                                                projectProp={projectProp}
                                                getClientsProp={getClientsProp}
                                                dateStartValidityProp={dateStartValidityProp}
                                                dateEndValidityProp={dateEndValidityProp}
                                                individualMonthReportProp={individualMonthReportProp}
                                                setRunRenderProp={setRunRender}
                                                runRenderProp={runRender}
                                                runRenderBlockProp={runRenderBlockProp}
                                                setRunRenderBlockProp={setRunRenderBlockProp}
                                                reloadObjectiveProp={reloadObjectiveProp}
                                                setReloadObjectiveProp={setReloadObjectiveProp}
                                                objectivesProp={objectivesProp}
                                                projectsUserSessionProp={projectsUserSessionProp}
                                                dateStartMonthlyReportProp={dateStartMonthlyReportProp}
                                                dateEndMonthlyReportProp={dateEndMonthlyReportProp}
                                                dataObjetiveProp={objectives}
                                                isMonthlyRepProp={isMonthlyRep}
                                            />
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                                {viewOption === 'multiSelect' && (
                                    <div className="p-field p-col-12 p-md-3" style={{ marginTop: '0.5rem' }}>
                                        <label className="labels" htmlFor="projectFilter" style={{ fontWeight: '500' }}>
                                            Filtrar por Proyectos:
                                        </label>
                                        <MultiSelect
                                            id="projectFilter"
                                            value={projectFilter}
                                            options={projects}
                                            onChange={handleProjectsChange}
                                            placeholder="Seleccione uno o más Proyectos"
                                            disabled={loadingProj}
                                            maxSelectedLabels={3}
                                            filter
                                            selectedItemsLabel={projectFilter?.length > 0 ? `${projectFilter.length} Elementos Seleccionados` : undefined}
                                            showClear
                                            className="p-column-filter"
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="p-grid" style={{ alignItems: 'start' }}>
                                    <div className="p-field p-col-12 p-md-5" style={{ marginTop: '5px' }}>
                                        <label className="labels" htmlFor="projectFilter" style={{ fontWeight: '500' }}>
                                            Filtrar por Proyectos:
                                        </label>
                                        <MultiSelect
                                            id="projectFilter"
                                            value={projectFilter}
                                            options={projects}
                                            onChange={handleProjectsChange}
                                            placeholder="Seleccione uno o más Proyectos"
                                            disabled={loadingProj}
                                            maxSelectedLabels={3}
                                            filter
                                            selectedItemsLabel={projectFilter?.length > 0 ? `${projectFilter.length} Elementos Seleccionados` : undefined}
                                            showClear
                                            className="p-column-filter"
                                        />
                                    </div>
                                    <div className="p-col-12 p-md-7" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>                                                                        
                                        {permissionsObjectiveCreate
                                            ?
                                            <BtnCrearObjetivo 
                                                idProjectProp={idProjectProp}
                                                projectProp={projectProp}
                                                getClientsProp={getClientsProp}
                                                dateStartValidityProp={dateStartValidityProp}
                                                dateEndValidityProp={dateEndValidityProp}
                                                individualMonthReportProp={individualMonthReportProp}
                                                setRunRenderProp={setRunRender}
                                                runRenderProp={runRender}
                                                runRenderBlockProp={runRenderBlockProp}
                                                setRunRenderBlockProp={setRunRenderBlockProp}
                                                reloadObjectiveProp={reloadObjectiveProp}
                                                setReloadObjectiveProp={setReloadObjectiveProp}
                                                objectivesProp={objectivesProp}
                                                projectsUserSessionProp={projectsUserSessionProp}
                                                dateStartMonthlyReportProp={dateStartMonthlyReportProp}
                                                dateEndMonthlyReportProp={dateEndMonthlyReportProp}
                                                dataObjetiveProp={objectives}
                                                isMonthlyRepProp={isMonthlyRep}
                                            />
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                            </>
                        )}
                        <Divider />
                    </>
                )}
            </div>
                {
                    showAllDataProp ?
                        <DataTable ref={dt} value={sendObjectivesExportProp} dataKey="id" header={header} globalFilter={globalFilter} loading={loadingProp}
                            emptyMessage="Objetivo no registrado en el sistema" paginator rows={10} responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px">
                            <Column className="columna" field="cliente" sortable header="Cliente" />
                            <Column className="columna" field="proyecto" sortable header="Proyecto" />
                            <Column className="columna" field="periodo" sortable header="Periodo" />
                            <Column className="columna" field="equipoTrabajo" sortable header="Equipo de Trabajo" />
                            <Column className="columna" field="nombreColaborador" sortable header="Nombre Colaborador" />
                            <Column className="columna" field="objetivo" sortable header="Objetivo" />
                            <Column className="columna" field="fechainicio" sortable header="Fecha Inicio" />
                            <Column className="columna" field="fechafin" sortable header="Fecha Fin" />
                            <Column className="column" field="cumplimiento" sortable header="%Cumplimiento" />
                        </DataTable>
                        :
                        <>
                            <DataTable ref={dt} value={objectives} dataKey="id" className="p-datatable-responsive-demo" header={header} globalFilter={globalFilter}
                                emptyMessage="Objetivo no registrado en el sistema" paginator rows={5} sortField={individualMonthReportProp === true ? "" : "descripcion"}
                                sortOrder={1} loading={individualMonthReportProp ? loadingProp : loading}
                                selection={readReportProp ? null : selectedObjective} onSelectionChange={readReportProp ? null : e => onSelectedObjectiveChange(e)} selectionMode={individualMonthReportProp === true ? "checkbox" : null}
                                onRowUnselect={readReportProp ? null : onRowUnselect} onRowSelect={readReportProp ? null : onRowSelect}
                                rowClassName={individualMonthReportProp ? rowClassName : null}
                            >
                                {individualMonthReportProp === true ?
                                    readReportProp ?
                                        null
                                        :
                                        <Column selectionMode="multiple" style={{ maxWidth: '1rem' }} ></Column>
                                    :
                                    null
                                }
                                <Column className="columna" field="id" sortable header="Id Objetivo" />
                                <Column className="columna" field="descripcion" sortable header="Objetivo" />
                                {individualMonthReportProp === true ?
                                    <Column className="columna" field="creadopor" sortable header="Creado por" />
                                    :
                                    null
                                }
                                <Column header="Acciones" body={acctions} />
                            </DataTable>

                            {individualMonthReportProp === true && disabledIndexProp === true && loadingProp === false ?
                                <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                                    <div style={{ display: 'contents' }} >
                                        <div className="p-field p-col-12 p-md-12" style={{ marginTop: '15px' }}>
                                            <Message severity="warn" text="Debe guardar los datos para cambiar de pestaña" />
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </>
                }
            </div>

        </div>
    )
}

export default ConsultarObjetivos