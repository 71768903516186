import React, { useState, useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";
import { TabView, TabPanel } from 'primereact/tabview';
import Services from "../../service/Services";
import DataTable from "../Logros/DataTable"
import { EstandarFormatoFecha, SumarDias } from "../Funciones";
import Cookies from "universal-cookie";
import axios from "axios";
import { CambiarFormatoFecha, isValidDate } from "../Funciones";

const cookies = new Cookies();

const Block4 = ({ getProjectsProp, individualMonthReportProp, setSelectedAchievementProp, objectMonthlyReportDataProp, readReportProp, listReportsUsersProp,
    reloadObjectiveProp, runRenderProp, selectedAchievementProp, allDataProp, showAllDataProp, loadingProp, setListAchievementProp, monthlyReportDataAllDataProp,
    selectedObjectiveProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp, servicesObjectivesProp,

    servicesWorkTeamProp, servicesClientsProjectUsersProp, projectAssignmentsProp, getClientsProp, monthlyReportProp}) => {


    const [achievementService, setAchievementService] = useState([])
    const [achievementServiceExport, setAchievementServiceExport] = useState([])
    const [objectives, setObjectives] = useState([]);
    const [projectsUserSession, setProjectsUserSession] = useState([]);
    const getServices = new Services()

    const [runRender, setRunRender] = useState(0)
    const [activeIndex, setActiveIndex] = useState(0);
    const [disabledIndex, setDisabledIndex] = useState(false);
    const [servicesClientsProjectUsers, setServicesClientsProjectUsers] = useState([]);
    const [servicesAchievement, setServicesAchievement] = useState([])
    const [servicesAchievementData, setServicesAchievementData] = useState([])

    useEffect(() => {
        setServicesClientsProjectUsers(servicesClientsProjectUsersProp)
    }, [servicesClientsProjectUsersProp])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const source = axios.CancelToken.source();
    
        const fetchAchievements = async (idObjetivos, setFunction) => {
            try {
                const data = await getServices.getachievementv2(idObjetivos);
                if (data !== undefined) {
                    setFunction(data);
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        const shouldFetch = () => {
            if (monthlyReportProp === "ownMonthlyReport" && selectedObjectiveProp.length !== 0 && readReportProp === false) {
                const idObjetivos = selectedObjectiveProp.map(objective => objective.id);
                fetchAchievements(idObjetivos, setServicesAchievementData);
            } else if (monthlyReportProp === "monthlyReportOtherOfficial" && servicesObjectivesProp.length !== 0) {
                const idObjetivos = servicesObjectivesProp.map(objective => objective.id);
                fetchAchievements(idObjetivos, setServicesAchievementData);
            } else if (monthlyReportProp === "allMonthlyReportOfOfficial" && showAllDataProp === true && servicesObjectivesProp.length !== 0) {
                const idObjetivos = servicesObjectivesProp.map(objective => objective.id);
                fetchAchievements(idObjetivos, setServicesAchievement);
            }else if(monthlyReportProp === "ownMonthlyReport" && servicesObjectivesProp.length !== 0 && readReportProp === true){
                const idObjetivos = servicesObjectivesProp.map(objective => objective.id);
                fetchAchievements(idObjetivos, setServicesAchievement);
            }
        }
        
        shouldFetch();
    
        return () => {
            if (source) {
                getServices.cancelRequest();
            }
        };
    }, [JSON.stringify(selectedObjectiveProp), JSON.stringify(servicesObjectivesProp), monthlyReportProp, showAllDataProp,runRender]); // eslint-disable-line react-hooks/exhaustive-deps
      

    useEffect(() => {
        if (objectMonthlyReportDataProp !== undefined && objectMonthlyReportDataProp.objetivos !== '[]') {
            setServicesAchievement(servicesAchievementData)
        }
        if (readReportProp === true) {

            setServicesAchievement(servicesAchievementData) 
        }
    }, [runRender, objectMonthlyReportDataProp, readReportProp,servicesAchievementData])// eslint-disable-line react-hooks/exhaustive-deps

    const usuario = cookies.get('usuario')
    useEffect(() => {
        if (showAllDataProp === true) {
            let newAllDataProp = []
            // Nuevo array para almacenar los datos filtrados
            const newDataFiltered = [];
            allDataProp.forEach(item => {
                var workTeamUser = []
                servicesWorkTeamProp.forEach(element => {
                    element.lider.forEach(x => {

                        if (x.colaborador.idusuario.id === item.usuario.id &&
                            getClientsProp.id === element.cliente.id) {
                            if (isValidDate(x.fechafinasignacion,
                                CambiarFormatoFecha(dateStartMonthlyReportProp),
                                CambiarFormatoFecha(dateEndMonthlyReportProp))) {
                                workTeamUser.push(element)
                            }

                        }
                    })
                    element.usuarios.forEach(x => {
                        if (x.usuario.id === item.usuario.id &&
                            getClientsProp.id === element.cliente.id) {
                            if (isValidDate(x.fechafinasignacion,
                                CambiarFormatoFecha(dateStartMonthlyReportProp),
                                CambiarFormatoFecha(dateEndMonthlyReportProp))) {
                                workTeamUser.push(element)
                            }

                        }
                    })
                })

                var period = JSON.parse(item.periodo)
                var achievements = JSON.parse(item.logros)
                /* let projects = JSON.parse(item.asignaciones) */
                var newGetAchievementFilter = []

                achievements?.forEach(i => {
                    const filter = servicesAchievement.filter(obj => {
                        return parseInt(obj.id) === parseInt(i.id)
                    })
                    filter.forEach(x => {
                        newGetAchievementFilter.push(x)
                    })
                })

                newGetAchievementFilter.forEach(y => {
                    newAllDataProp.push({
                        cliente: item.cliente.razonsocial,
                        periodo: new Date(period.dateEnd).toLocaleString("es-US", { month: "long" }) + ' ' + new Date(period.dateEnd).getFullYear(),
                        equipoTrabajo: workTeamUser.length !== 0 ? workTeamUser[0].nombreequipotrabajo : '',
                        nombreColaborador: item.usuario.nombre + ' ' + item.usuario.apellido,
                        objetivo: y.objetivo.descripcion,
                        cumplimientoalcorte: y.cumplimientoalcorte,
                        logro: y.logro,
                        detalleLogro: y.detallelogro,
                        fechacumplimiento: y.fechafinalizacion,
                        proyecto: y.proyecto.nombreproyecto,
                        id: y.id
                    })
                })
            })
            const uniqueCombos = {};
            // Recorrer el array original y filtrar los datos
            for (const data of newAllDataProp) {
                const comboKey = `${data.nombreColaborador}-${data.proyecto}-${data.objetivo}-${data.logro}`;

                if (!uniqueCombos[comboKey]) {
                    uniqueCombos[comboKey] = true;
                    newDataFiltered.push(data);
                }
            }
            setAchievementServiceExport(newDataFiltered)

        } else {
            var filterObjectives = []
            selectedObjectiveProp.forEach(element => {
                const result = servicesObjectivesProp?.filter(obj => obj.id === element.id)
                result.forEach(x => {
                    filterObjectives.push(x)
                })
            })
            var uniqueFilterObjectives = [...new Set(filterObjectives)]
            setObjectives(uniqueFilterObjectives)


            if (servicesClientsProjectUsers.length !== 0) {
                setProjectsUserSession(servicesClientsProjectUsers)
            }

            const newProjecAssignment = projectAssignmentsProp.map(item => {
                return {
                    dateEndValidity: item.dateEndValidity,
                    dateStartValidity: item.dateStartValidity,
                    id: item.id,
                    project: item.project
                }
            })
            setSelectedProject(newProjecAssignment)
            var d = new Date()
            var gmtHours = -d.getTimezoneOffset() / 60;
            var newData = servicesAchievement?.map(item => {
                var newDate
                if (gmtHours < 0) {
                    newDate = SumarDias(new Date(item.fechafinalizacion), +1)
                } else {
                    newDate = new Date(item.fechafinalizacion)
                }
                return {
                    actualizadopor: item.actualizadopor,
                    creadopor: item.creadopor,
                    detallelogro: item.detallelogro,
                    estado: item.estado,
                    fechaactualizado: item.fechaactualizado,
                    fechacreacion: item.fechacreacion,
                    fechafinalizacion: EstandarFormatoFecha(newDate),
                    sendDate: item.fechafinalizacion,
                    id: item.id,
                    logro: item.logro,
                    objetivo: item.objetivo,
                    proyecto: item.proyecto,
                    cumplimientoalcorte: item.cumplimientoalcorte
                }
            })
            var newUniqueDataCreatedBy = newData.filter(function (object) {
                var createdBy = object.creadopor
                return createdBy === usuario
            })
            var newUniqueDataNoCreatedBy = newData.filter(function (object) {
                var createdBy = object.creadopor
                return createdBy !== usuario
            })
            var sendData = newUniqueDataCreatedBy.concat(newUniqueDataNoCreatedBy)
            let newFilterObjectives = []
            selectedObjectiveProp.forEach(element => {
                const result = sendData.filter(obj => obj.objetivo.id === element.id)
                result.forEach(x => {
                    newFilterObjectives.push(x)
                })
            })
            let newUniqueFilterObjectives = [...new Set(newFilterObjectives)]
            if (readReportProp) {
                setAchievementService(sendData)
            } else {
                if (objectMonthlyReportDataProp !== undefined && objectMonthlyReportDataProp.objetivos !== "[]") {
                    setAchievementService(newUniqueFilterObjectives)
                }
            }
            setListAchievementProp(newUniqueFilterObjectives)
            setListAchievementProp(sendData)

            setActiveIndex(0)
        }

    }, [projectAssignmentsProp, runRender, readReportProp, listReportsUsersProp, showAllDataProp, allDataProp, selectedObjectiveProp, servicesObjectivesProp, servicesClientsProjectUsers, servicesAchievement, servicesWorkTeamProp, objectMonthlyReportDataProp, dateStartMonthlyReportProp, dateEndMonthlyReportProp, getClientsProp]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (loadingProp === true) {
            setDisabledIndex(true)
        } else {
            setDisabledIndex(false)
        }
    }, [runRenderProp, loadingProp])

    // obtener datos del select proyecto

    const [selectedProject, setSelectedProject] = useState(null)

    const projects = projectAssignmentsProp.map(item => {
        const obj = { project: item.project, id: item.id, dateStartValidity: item.dateStartValidity, dateEndValidity: item.dateEndValidity }
        return obj
    })

    const onProjectChange = (e) => {
        setSelectedProject(e.value)
    }

    var condition = false

    if (projectAssignmentsProp !== null && selectedProject !== null) {
        if (projectAssignmentsProp.length !== 0) {
            condition = true
        }
    }

    return (
        <>
            <div className="datatable-responsive-demo">
                <h3 className="labels">Logros</h3>
            </div>
            {showAllDataProp ?
                null
                :
                <div className="p-fluid p-formgrid p-grid" style={{ alignItems: 'baseline' }}>
                    <div style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-3" style={{ marginTop: '5px' }}>
                            <label className="labels" >Proyectos:</label>
                            <MultiSelect value={selectedProject} options={projects} onChange={onProjectChange} placeholder="Seleccione un proyecto"
                                className="p-column-filter " emptyMessage="No hay resultados" name="project" optionLabel="project" showClear
                            />
                        </div>
                    </div>
                </div>
            }
            <div className="datatable-responsive-demo">
                <div className="card">
                    {showAllDataProp ?
                        <DataTable
                            readReportProp={readReportProp}
                            listReportsUsersProp={listReportsUsersProp}
                            setSelectedAchievementProp={setSelectedAchievementProp}
                            selectedAchievementProp={selectedAchievementProp}
                            individualMonthReportProp={individualMonthReportProp}
                            reloadObjectiveProp={reloadObjectiveProp}
                            getProjectsProp={getProjectsProp}
                            runRenderProp={runRenderProp}
                            objectMonthlyReportDataProp={objectMonthlyReportDataProp}
                            achievementServiceProp={achievementServiceExport}
                            setRunRender={setRunRender}
                            runRender={runRender}
                            objectivesProp={objectives}
                            projectsUserSessionProp={projectsUserSession}
                            showAllDataProp={showAllDataProp}
                            loadingProp={loadingProp}
                        />
                        :
                        <TabView scrollable activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            {condition ?
                                selectedProject.map((item, i) => {
                                    var sendSelectedObjectiveProp = selectedObjectiveProp.filter(obj => obj.proyecto === item.id)
                                    var panel = (
                                        <TabPanel header={item.project} disabled={disabledIndex} key={i}>
                                            <DataTable
                                                idProjectsProp={item.id}
                                                projectProp={item.project}
                                                dateStartValidity={item.dateStartValidity}
                                                dateEndValidity={item.dateEndValidity}
                                                readReportProp={readReportProp}
                                                listReportsUsersProp={listReportsUsersProp}
                                                setSelectedAchievementProp={setSelectedAchievementProp}
                                                selectedAchievementProp={selectedAchievementProp}
                                                individualMonthReportProp={individualMonthReportProp}
                                                reloadObjectiveProp={reloadObjectiveProp}
                                                getProjectsProp={getProjectsProp}
                                                runRenderProp={runRenderProp}
                                                objectMonthlyReportDataProp={objectMonthlyReportDataProp}
                                                achievementServiceProp={achievementService}
                                                setRunRender={setRunRender}
                                                runRender={runRender}
                                                objectivesProp={objectives}
                                                projectsUserSessionProp={projectsUserSession}
                                                showAllDataProp={showAllDataProp}
                                                disabledIndexProp={disabledIndex}
                                                setDisabledIndexProp={setDisabledIndex}
                                                monthlyReportDataAllDataProp={monthlyReportDataAllDataProp}
                                                selectedObjectiveProp={sendSelectedObjectiveProp}
                                                loadingProp={loadingProp}
                                                dateStartMonthlyReportProp={dateStartMonthlyReportProp}
                                                dateEndMonthlyReportProp={dateEndMonthlyReportProp}
                                            />
                                        </TabPanel>
                                    )
                                    return panel
                                })
                                :
                                <TabPanel header="seleccionar un proyecto">
                                </TabPanel>
                            }
                        </TabView>
                    }
                </div>
            </div>
        </>
    )
}

export default Block4