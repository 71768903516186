import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';

import Cookies from 'universal-cookie'
const cookies = new Cookies()

const urlUpdate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/deliverable/update'
const urlCreate = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/deliverable/create'


const ConfirmBtn = ({ idProjectProp, idObjectiveProp, deliverableProp, idDeliverableProp, percentageProp, dateProp, idProyectProp, setShowModal,
    projectObjectiveCreationDate, idObjectiveDeliverableProp, deleteDetailProp, creationDateObjectiveProp,
    creationDateProp, createdByProp, assingnedNewObjectiveDeliverableProp, setRunRenderProp, createNewDeliverableProp, listObjectivesProp,
    runRenderProp, setDisplayBasicProp, statusProp, runRenderBlockProp, setRunRenderBlockProp, noDeleteSDeliverableProp,
    tittle, menssage, action, icon, classProp, label, tooltipProp, setNewResetDataProp }) => {


    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [disabledButton, setDisabledButton] = useState(false);

    const service = () => {
        if (action === "Create") {
            createDeliverable()
            setDisabledButton(true)
        } else if (action === "Update") {
            setDisabledButton(true)
            updateDeliverable()
        } else if (action === "Delete") {
            setDisabledButton(true)
            deleteDeliverable()
        }
    }

    const usuario = cookies.get('usuario')
    const createDeliverable = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                creadopor: usuario,
                descripcion: deliverableProp,
                estado: 1,
                fechaactualizado: "",
                fechacreacion: new Date(),
                objetivos: createNewDeliverableProp ?
                    listObjectivesProp.map(item => {
                        return {
                            ...item,
                            id: undefined
                        }
                    })
                    :
                    [{
                        estado: 1,
                        fechacreacion: new Date(),
                        fechacumplimiento: dateProp,
                        porcentajecumplimiento: percentageProp,
                        objetivo: {
                            id: idObjectiveProp
                        },
                        proyecto: {
                            id: idProjectProp
                        }
                    }]
            }
        )
        /* console.log(json) */
        await axios.post(urlCreate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                if (setShowModal) {
                    setShowModal(true)  // Solo mostrar el modal
                }
                
                if (setDisplayBasicProp) {
                    setDisplayBasicProp(false)
                }
                
                setDisplayConfirmation(false)
                setDisabledButton(false)
                setNewResetDataProp(true)
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error?.response?.data?.mensaje, life: 6000 });
            })
    }

    const updateDeliverable = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: usuario,
                creadopor: createdByProp,
                descripcion: deliverableProp,
                estado: 1,
                fechaactualizado: new Date(),
                fechacreacion: creationDateProp,
                id: idDeliverableProp,
                objetivos: assingnedNewObjectiveDeliverableProp ? [{
                    estado: statusProp,
                    fechacreacion: deleteDetailProp === true ? creationDateObjectiveProp : new Date(),
                    fechacumplimiento: dateProp,
                    id: idObjectiveDeliverableProp,
                    porcentajecumplimiento: percentageProp,
                    objetivo: {
                        id: idObjectiveProp
                    },
                    proyecto: {
                        id: parseInt(idProjectProp)
                    },
                }]
                    :
                    []
            })
        /* console.log(json) */
        await axios.post(urlUpdate, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                // console.log(response)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    if (setRunRenderBlockProp !== undefined) {
                        setRunRenderBlockProp(runRenderBlockProp + 1)
                    }

                    if (deleteDetailProp === false) {
                        setDisplayBasicProp(false)
                    }
                    setDisplayConfirmation(false)
                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const urlDelete = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/deliverable/delete?id=' + idDeliverableProp
    const deleteDeliverable = async () => {
        await axios.delete(urlDelete,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    if (setRunRenderBlockProp !== undefined) {
                        setRunRenderBlockProp(runRenderBlockProp + 1)
                    }
                    setDisplayConfirmation(false)
                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }

    const renderFooter = (name) => {
        return (
            <div>
                {noDeleteSDeliverableProp ?
                    <Button label="Aceptar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    :
                    <>
                        <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                        <Button label="Aceptar" icon="pi pi-check" onClick={() => service()} autoFocus disabled={disabledButton} />
                    </>
                }
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icon} onClick={() => onClick('displayConfirmation')} className={classProp} style={{ margin: '5px 5px 0px' }}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            />
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="modalConfirm" header={tittle} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                        <div className="confirmation-content-modal">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>{menssage}</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default ConfirmBtn