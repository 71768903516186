import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import axios from 'axios';
import moment from 'moment';
import Services from '../../service/Services';
import { CambiarFormatoFecha, RestarDias, SumarDias } from '../Funciones';

const urlActualizar = process.env.REACT_APP_BASE_URL + '/v1/api/user/updateUser'
const urlCrear = process.env.REACT_APP_BASE_URL + '/v1/api/user/create'
const urlCreateTeam = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/create'
const urlUpdateTeam = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/update'
const urlCheckAct = process.env.REACT_APP_BASE_URL + '/v1/api/activity/consultListActivityRange'

const ConfirmBtn = ({ tipoUsuarioProp, idTipoUsuarioProp, numeroDocProp, tipoDocumentoProp, idTipoDocumentoProp, razonSocialProp, nombreProp, apellidoProp, cargoProp, cargosProp, idCargoProp, usuarioProp, contraseñaProp,
    rolProp, idRolProp, estadoProp, idEstadoProp, proyectosProp, proyectosOldProp, fechaIngresoProp, creadoporProp, fechaCreacionProp, bloqueoProp, lastDateRegisterProp, idBloqueoProp, updateDateProp, intentosProp, titulo, mensaje, accion, icono, label, usuarioSesionProp,
    sendArrayProjectProp, nameTeamWorkProp, idProjectProp, idUserProp, dateStartWorkTeamProp, dateEndWorkTeamProp, dataCoordinator, dataOfficial, idClientTeamWork, updateClientTeam, updateDateStartProp, updateDateEndProp,
    updateCoordinatorTeam, updateNameTeam, updateOfficialTeam, idUpdate, dateCreateProp, createByProp, runRenderProp, setRunRenderProp, setDisplayBasicOfficialProp,
    idTeamWork, classProp, tooltipProp, setOpen, clientProp, nameTeamProp, dateStartTeamProp, dateEndTeamProp, coordinatorProp, officialProp, setValidateChange,
    idCoordinator, arrayGroupOfficial, fechaactualizadoProp, lastIdCargoProp, updatePositionHistoryProp, updateStatusHistoryProp, lastIdEstadoProp, lastIdTipoBloqueo,
    lastFechaingresoProp, dataContactClienProp, ProveedorSeleccionadoProp, addOficialProp, setSelectedOfficialProp,
    setDateStartOfficialProp, setDateEndOfficialProp, editOfficialProp, sendArrayDatesPositionProp, 
    dataCompletedProp, historicoHorasProp, eliminarCargoProp, idCargoEliminadoProp, 
    dateStartPositionProp, dateEndPositionProp, idAsociacionCargoProp, setNewArrayGroupCoordinatorProp,mostrarHorasAdicionalesProp }) => {

    const toast = useRef(null);
    const getServices = new Services();

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);

    }

    const [disabled, setDisabled] = useState(false)
    const servicio = () => {
        if (accion === "Crear") {
            crearUsuario()
            setDisabled(true)
        } else if (accion === "Actualizar") {
            actualizarUsuario()
            setDisabled(true)
        }
        else if (accion === "ActualizarVigencia") {
            actualizarVigenciaUsuario()
            setDisabled(true)
        }
        else if (accion === "Actualizarhistoricocargo") {
            actualizarVigenciaCargo()
            setDisabled(true)
        }
        else if (accion === "CrearEquipoTrabajo") {
            createTeamWork()
            setDisabled(true)
        }
        else if (accion === "CrearFuncionarios") {
            updateTeamWork()
            setDisabled(true)
        }
        else if (accion === "ActualizarEquipo") {
            updateTeamWork()
            setDisabled(true)
        }
        else if (accion === "ActualizarFuncionario") {
            updateTeamWork()
            setDisabled(true)
        }
        else if (accion === "EliminarFuncionario") {
            searchActivityof()
            setDisabled(true)
        }
        else if (accion === "EliminarEquipoTrabajo") {
            deleteTeamWork()
            setDisabled(true)
        }
    }

    const proyectos = []
    if (proyectosProp !== null) {
        if (proyectosProp !== undefined) {
            proyectosProp.forEach(element => {
                var proyectosSeleccionados = {}
                proyectosSeleccionados.id = ""
                proyectosSeleccionados.estado = 1
                proyectosSeleccionados.fechacreacion = ""
                proyectosSeleccionados.proyecto = {
                    id: element.id,
                    nombreproyecto: element.name
                }
                proyectos.push(proyectosSeleccionados)
            });

        }
    }

    const proyectosOld = []
    if (proyectosOldProp !== undefined) {
        proyectosOldProp.forEach(element => {
            var proyectosSeleccionados = {}
            proyectosSeleccionados.id = element.idR
            proyectosSeleccionados.estado = 1
            proyectosSeleccionados.fechacreacion = ""
            proyectosSeleccionados.proyecto = {
                id: element.id,
                nombreproyecto: element.name
            }
            proyectosOld.push(proyectosSeleccionados)
            /* console.log(proyectosOld) */
        })

    }

    const proyectosUnicos = []

    if (proyectos !== null) {
        proyectos.forEach(i => {
            var existe = false
            proyectosOld.forEach(j => {
                !existe && i.proyecto.id === j.proyecto.id && (existe = true)
            })
            !existe && proyectosUnicos.push({
                id: "",
                estado: 1,
                fechacreacion: "",
                proyecto: {
                    id: i.proyecto.id
                }
            })
        })

    }
    /* console.log(proyectosProp)
    console.log(proyectosOldProp) */


    if (proyectos !== null) {
        proyectosOld.forEach(i => {
            var existe = false
            proyectos.forEach(j => {
                !existe && i.proyecto.id === j.proyecto.id && (existe = true)
            })
            !existe && proyectosUnicos.push({
                id: i.id,
                estado: 3,
                fechacreacion: "",
                proyecto: {
                    id: i.proyecto.id
                }
            })
        })
    }
    const crearUsuario = async () => {

        const json = JSON.stringify(
            {
                actualizadopor: "",
                apellido: apellidoProp,
                contrasena: contraseñaProp,
                creadopor: creadoporProp,
                descripcion: "",
                estado: 0,
                fecIngreso: fechaIngresoProp,
                fechaactualizado: "",
                fechacreacion: "",
                idcargo: idCargoProp,
                idempresa: 1,
                idrol: idRolProp,
                idtipobloqueo: 1,
                nombre: nombreProp,
                nombretipodocumento: tipoDocumentoProp,
                numerodocumento: numeroDocProp,
                proyectos: proyectos,
                tipo: {
                    id: idTipoUsuarioProp
                },
                usuario: usuarioProp,
                proveedor: {
                    id: parseInt(idTipoUsuarioProp) === 4 ? ProveedorSeleccionadoProp : 0
                },
                historicohoras: historicoHorasProp,
                mostrarHorasAdicionales: mostrarHorasAdicionalesProp
            }
        )
        /* console.log(json) */
        await axios.post(urlCrear, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro Satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setDisplayConfirmation(false);
                setOpen(true)
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 406) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response.data.mensaje, life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    /* console.log(idEstadoProp)
    console.log(idBloqueoProp) */
    
    const actualizarUsuario = async () => {
        let dateStartPosition = ''
        let dateEndPosition = ''
        let lastDayToDateCurrent = RestarDias(new Date(), 1) //Obtener un día menos a la fecha actual

        if (updatePositionHistoryProp === true && eliminarCargoProp===false) {
            if (dataCompletedProp.cargos.length === 0) {
                dateStartPosition = dataCompletedProp.sendFecIngreso
                dateEndPosition = CambiarFormatoFecha(lastDayToDateCurrent)
            } else {
                // Usando el método reduce para encontrar la fecha de finalización más grande
                const fechaFinMayor = dataCompletedProp.cargos.reduce((fechaMayor, evento) => {
                    // Comparando las fechas y devolviendo la más grande
                    return evento.fechafinasignacion > fechaMayor ? evento.fechafinasignacion : fechaMayor;
                }, dataCompletedProp.cargos[0]?.fechafinasignacion);

                var d = new Date()
                var gmtHours = -d.getTimezoneOffset() / 60;
                let newDateStartPosition = new Date(fechaFinMayor)

                if (gmtHours < 0) {
                    newDateStartPosition = SumarDias(new Date(fechaFinMayor), 1)
                } else {
                    newDateStartPosition = new Date(fechaFinMayor)
                }
                dateStartPosition = newDateStartPosition
                dateEndPosition = CambiarFormatoFecha(lastDayToDateCurrent)
            }
        }
        const json = JSON.stringify(
            {
                actualizadopor: usuarioSesionProp,
                apellido: apellidoProp,
                comentariobloqueo: bloqueoProp,
                creadopor: creadoporProp,
                estado: idEstadoProp,
                fecIngreso: fechaIngresoProp,
                fechaactualizado: "",
                fechacreacion: fechaCreacionProp,
                idcargo: idCargoProp,
                idrol: idRolProp,
                idtipobloqueo: idEstadoProp === 1 ? 1 : idBloqueoProp,
                intentos: idEstadoProp === 1 ? 0 : intentosProp,
                nombre: nombreProp,
                nombretipodocumento: tipoDocumentoProp,
                numerodocumento: numeroDocProp,
                ultimafecharegistro: idEstadoProp === 1 ? null : lastDateRegisterProp,
                usuario: usuarioProp,
                proyectos: proyectosUnicos,
                historicoCargo: updatePositionHistoryProp,
                cargos: updatePositionHistoryProp===true && eliminarCargoProp===false? [{
                    cargo: {
                        id: lastIdCargoProp
                    },
                    fechainicioasignacion: dateStartPosition,
                    fechafinasignacion: dateEndPosition,
                    estado: 0,
                }]
                    :
                    updatePositionHistoryProp===true && eliminarCargoProp===true ? [{
                        cargo: {
                            id: idCargoEliminadoProp,
                        },
                        fechainicioasignacion: dateStartPositionProp,
                        fechafinasignacion: dateEndPositionProp,
                        estado: 3,
                        id:idAsociacionCargoProp
                    }]
                        :
                    [],
                historicoestados: updateStatusHistoryProp ?
                    lastIdEstadoProp === 1 ?
                        [{
                            estadoanterior: lastIdEstadoProp,
                            fechaingreso: fechaIngresoProp,
                            ultimafecharegistro: null,
                            estado: 0,
                            tipobloqueo: {
                                id: lastIdTipoBloqueo
                            },
                            creadopor: usuarioSesionProp
                        }]
                        :
                        [{
                            estadoanterior: lastIdEstadoProp,
                            fechaingreso: lastFechaingresoProp,
                            ultimafecharegistro: lastDateRegisterProp,
                            estado: 0,
                            tipobloqueo: {
                                id: lastIdTipoBloqueo
                            },
                            creadopor: usuarioSesionProp
                        }]
                    :
                    [],
                    historicohoras: historicoHorasProp,
                    proveedor: {
                        id: parseInt(idTipoUsuarioProp) === 4 ? ProveedorSeleccionadoProp : 0
                    },
                    tipo: {
                        id: idTipoUsuarioProp
                    },
                    mostrarHorasAdicionales: mostrarHorasAdicionalesProp
            }
        )
        /* console.log(json) */
        await axios.post(urlActualizar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/consultarUsuarios"
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const actualizarVigenciaUsuario = async () => {

        const json = JSON.stringify(
            {
                usuario: usuarioProp,
                proyectos: sendArrayProjectProp,
                estado: estadoProp
            }
        )
        await axios.post(urlActualizar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)
                    setDisplayBasicOfficialProp(false)

                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response, life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const actualizarVigenciaCargo = async () => {

        const json = JSON.stringify(
            {
                usuario: usuarioProp,
                cargos: sendArrayDatesPositionProp,
                estado: 1,
                idcargo: idCargoProp,
                historicoCargo: true,
                actualizadopor: usuarioSesionProp
            }
        )
        await axios.post(urlActualizar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)
                    setDisplayBasicOfficialProp(false)

                }, [100])
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: error.response, life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }
    const createTeamWork = async () => {
        const json = JSON.stringify(
            {
                actualizadopor: '',
                creadopor: usuarioSesionProp,
                estado: 1,
                fechaactualizado: '',
                fechacreacion: new Date(),
                fechafinvigencia: dateEndWorkTeamProp,
                fechainiciovigencia: dateStartWorkTeamProp,
                lider: dataCoordinator,
                nombreequipotrabajo: nameTeamWorkProp,
                cliente: { id: idClientTeamWork },
                usuarios: dataOfficial,
                contactoCliente: dataContactClienProp
            }
        )
        /* console.log(json) */
        await axios.post(urlCreateTeam, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)
                    clientProp({
                        customer: '',
                        id: '',
                        dominio: ''
                    })
                    setDisplayBasicOfficialProp(false)
                    nameTeamProp(null)
                    dateStartTeamProp(null)
                    dateEndTeamProp(null)
                    coordinatorProp('')
                    officialProp([])
                    setValidateChange(false)
                    arrayGroupOfficial([])
                    idCoordinator([])
                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const searchActivityof = async () =>{
        let emailOfUser = ""
        await getServices.getUsersByClient({idClientes:[updateClientTeam]}).then(data => { // eslint-disable-next-line
            let dataUser = data.filter(object => {
                if(object.idUsuario === updateOfficialTeam[0].usuario.id){
                    return object
                }
            })
            emailOfUser = dataUser[0].usuario
        })

        let today = moment().format('YYYY-MM-DD');
            
        const data = {
            fecFinal: today,
            fecInicial: updateDateStartProp,
            idProyectos: [],
            numeroDocumentos: [],
            idEquipoTrabajos: [idUpdate],
            idProveedores: [],
            correo: emailOfUser
        };
        
        const json = JSON.stringify(data)

        await axios.post(urlCheckAct, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(response =>{
                if(response.data.length === 0){
                    updateTeamWork()
                }else{
                    setDisplayConfirmation(false)
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El Colaborador tiene actividades con este equipo de trabajo', life: 5000 });
                }
            })
    }

    const updateTeamWork = async () => {
        const json = JSON.stringify(
            {
                actualizadopor: usuarioSesionProp,
                cliente: { id: updateClientTeam },
                creadopor: createByProp,
                estado: 1,
                id: idUpdate,
                fechaactualizado: new Date(),
                fechacreacion: dateCreateProp,
                fechafinvigencia: updateDateEndProp,
                fechainiciovigencia: updateDateStartProp,
                lider: updateCoordinatorTeam,
                nombreequipotrabajo: updateNameTeam,
                usuarios: updateOfficialTeam,
                contactoCliente: dataContactClienProp
            }
        )
        await axios.post(urlUpdateTeam, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización satisfactoria', detail: response.data.message, life: 3000 });
                // console.log(response)

                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)
                    if (accion === 'ActualizarEquipo') {
                        setNewArrayGroupCoordinatorProp([])
                    }
                    if (addOficialProp === true) {
                        setDisplayBasicOfficialProp(false)
                        setSelectedOfficialProp({
                            name: '',
                            id: ''
                        })
                        setDateStartOfficialProp(null)
                        setDateEndOfficialProp(null)
                    } else if (editOfficialProp === true) {
                        setDisplayBasicOfficialProp(false)
                    }
                }, [500])

            })
            .catch(function (error) {
                //console.log(error.response)
                if (error.response.status === 500) {
                    toast.current.show({ severity: 'error', summary: 'Algo ha ocurrido!', detail: 'El número de documento ya existe en el sistema', life: 4000 });
                } else {
                    toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
                }
            })
    }

    const urlDelete = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/workTeam/delete?id=' + idTeamWork

    const deleteTeamWork = async () => {
        await axios.delete(urlDelete,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                setTimeout(() => {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayConfirmation(false)

                }, [500])
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Aceptar" icon="pi pi-check" onClick={() => servicio()} autoFocus disabled={disabled} />
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icono} onClick={() => onClick('displayConfirmation')} className={classProp} tooltip={tooltipProp} style={{ margin: '5px 5px 0px' }}
                tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            <Dialog className="modalConfirm" header={titulo} visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                <div className="confirmation-content">
                    {updateDateProp ? "" :
                        <div className="p-fluid p-grid">
                            <div className=" p-col-12 p-md-6">
                                <label className="labelsConfirm" >Nombre</label>
                                <InputText value={nombreProp} disabled />
                            </div>

                            <div className=" p-col-12 p-md-6">
                                <label className="labelsConfirm" >Cargo</label>
                                <InputText value={cargoProp} disabled />
                            </div>

                            <div className=" p-col-12 p-md-6">
                                <label className="labelsConfirm" >Usuario</label>
                                <InputText value={usuarioProp} disabled />
                            </div>

                            <div className=" p-col-12 p-md-6">
                                <label className="labelsConfirm" >rol</label>
                                <InputText value={rolProp} disabled />
                            </div>
                        </div>
                    }

                    <div className="mensajeCofirmar">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span>{mensaje}</span>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ConfirmBtn