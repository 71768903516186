import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
/* import { FaBars } from 'react-icons/fa'; */
import '../../../assets/css/Sidebar.css'
//import IMG from '../../../assets/images/LOGO-LINEXPERTS-COLOR.png'
//import IMG3 from '../../../assets/images/Logo_Timevalue _transpa_sin_slogan.png'
import IMG2 from '../../../assets/images/Sello_linexperts_X.png'

import Cookies from 'universal-cookie';
import { UseMedia } from '../../Funciones'

const cookies = new Cookies();

const Header = () => {
    const [logoEmpresaUrl, setLogoEmpresaUrl] = useState('');
    const [logoMarcaUrl, setLogoMarcaUrl] = useState('');

    const cerrarSession = () => {
        cookies.remove('idColaborador', { path: "/" });
        cookies.remove('idUsuario', { path: "/" });
        cookies.remove('code', { path: "/" });
        cookies.remove('message', { path: "/" });
        cookies.remove('usuario', { path: "/" });
        cookies.remove('cargo', { path: "/" });
        cookies.remove('nombre', { path: "/" });
        cookies.remove('apellido', { path: "/" });
        cookies.remove('numeroDocumento', { path: "/" });
        cookies.remove('tipoDocumento', { path: "/" });
        cookies.remove('cierreActivo', { path: "/" });
        cookies.remove('ultimoMesCerrado', { path: "/" });
        cookies.remove('mesDefinitivoCerrado', { path: "/" });
        sessionStorage.removeItem("token", { path: "/" });
    };

    let small = UseMedia("(max-width: 760px)");

    useEffect(() => {
        setLogoEmpresaUrl(`${process.env.REACT_APP_URL_LOGO}/logoempresa/logo.png?${new Date().getTime()}`);
        setLogoMarcaUrl(`${process.env.REACT_APP_URL_LOGO}/logomarca/logo.png?${new Date().getTime()}`);
    }, []);

    return (
        <div>
            {
                small
                    ?
                    <div className="header">
                        <div className="left_area">
                            <Link to="/registroActividades/inicio">
                                <img src={IMG2} style={{ width: '40px', height: '40px', marginTop: '-5px' }} className="imgMenu" alt="logo linexperts web" />
                            </Link>
                        </div>
                        <img src={logoMarcaUrl} className="imgMenu2" alt="logo linexperts web" />
                        <div className="right_area">
                            <Link to="/singOff" onClick={() => cerrarSession()} className="logout_btn">Cerrar Sesión</Link>
                        </div>
                    </div>
                    :
                    <div className="header">
                        <div className="left_area">
                            <Link to="/registroActividades/inicio">
                                <img src={logoEmpresaUrl} className="imgMenu" alt="logo linexperts web" />
                            </Link>
                        </div>
                        <img src={logoMarcaUrl} className="imgMenu2" alt="logo linexperts web" />
                        <div className="right_area">
                            <Link to="/singOff" onClick={() => cerrarSession()} className="logout_btn">Cerrar Sesión</Link>
                        </div>
                    </div>
            }
        </div>
    );
};

export default Header;

